<template>
  <div v-frag>
    <hero-with-image :title="pageTitle" subtitle="repairForm"></hero-with-image>
    <dolgozoi-form :service-id="serviceId" :is-reused-form="isReusedForm"></dolgozoi-form>
  </div>
</template>

<script>
import HeroWithImage from 'src/components/titles/HeroWithImage';
import DolgozoiForm from 'src/components/forms/DolgozoiForm';

export default {
  name: 'DolgozoiPage',
  components: {
    HeroWithImage, DolgozoiForm,
  },
  props: {
    pageTitle: {
      type: String,
      default: 'dolgozoknak',
    },
    serviceId: {
      type: Number,
      default: 300,
    },
  },
  async beforeMount() {
    this.isReusedForm = this.$route.query.reuse || 'not';
  },
  mounted() {
    //
  },
  data() {
    return {
      isReusedForm: 'not',
    };
  },
  methods: {
    //
  },
  watch: {
    //
  },
};
</script>

<style scoped>

</style>
